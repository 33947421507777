.MapContainer {
  @include responsive(large) {
    max-width: calc(100% - 320px); // 320px = width of RightBar
  }

  // used to display a blurred fast-loading image
  // which will be overwritten by the loading
  // leaflet map. when map crashes, this bg-img will
  // fill up the space
  background-image: url("../images/preloading-blur-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.Map {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.customPin {
  background-image: url(../images/marker.svg);
  background-size: contain;
  width: 1.2rem;
  height: 1.2rem;
  left: -0.6rem;
  top: -0.6rem;
  display: block;
  position: relative;
  opacity: 0.6;

  &-clicked {
    cursor: pointer;
    background-image: url(../images/marker-icon.png);
    background-size: cover;
    width: 25px;
    height: 41px;
    left: -12.5px;
    top: -41px;
    display: block;
    position: relative;
    opacity: 0.6;
  }
}

.customPin2,
.customPinIcon2 {
  cursor: pointer;
  background-image: url(../images/marker-icon.png);
  background-size: cover;
  width: 25px;
  height: 41px;
  left: -12.5px;
  top: -41px;
  display: block;
  position: relative;
  opacity: 0.6;

  &--clicked {
    filter: invert(0.3);
  }
}

.marker-cluster {
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: padding-box;
  border-radius: 30px !important;
  margin-left: -30px !important;
  margin-top: -30px !important;
  width: 60px !important;
  height: 60px !important;
  color: white;

  div {
    background-color: #c2087199;
    padding: 5px;
    text-align: center;
  }
}
.withBorder {
  .marker-cluster {
    border: 2px dashed #c2087199;
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 0px !important;
}

.leaflet-bar {
  border-radius: 0px !important;
}

.leaflet-bar > a {
  border-radius: 0px !important;
}

.leaflet-container a.leaflet-popup-close-button {
  display: block;
  padding: 10px;
  color: #555;
  width: unset;

  &:hover {
    color: #111;
  }
}

.leaflet-popup-content .HelpGroup__Title {
  margin-left: 0px;
  margin-right: 15px;
}

.leaflet-popup-content {
  margin-left: 0px;
  margin-right: 0px;
}

@media screen and (max-width: 767px) {
  .MapContainer {
    height: 70vh;
  }
}

.StatesMap > div {
  min-width: 300px;
  min-height: 300px;
}

.marker-popup {
  position: relative;
  border: 1px solid lightgray;
  background-color: white;
  box-shadow: $tile-shadow;
  z-index: 900;
  top: -25px;
  left: -25px;
  display: flex;
}

.cluster-marker {
  background-clip: padding-box;
  border: 2px dashed #c2087199;
  border-radius: 30px !important;
  color: #fff;
  height: 60px;
  width: 60px;
  position: relative;
}

.cluster-marker:hover {
  border-radius: 0px;
  border: 2px solid transparent;
}

.cluster-marker div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cluster-marker div span {
  background-color: #c2087199;
  text-align: center;
  padding: 5px;
}

.StatesMap > div {
  min-width: 300px;
  min-height: 300px;
}

.marker-popup {
  position: relative;
  border: 1px solid lightgray;
  background-color: white;
  box-shadow: $tile-shadow;
  z-index: 900;
  top: -25px;
  left: -25px;
}

.pigeon-control-container {
  z-index: 2;
}
