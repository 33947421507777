@import "vars";

.Header {
  position: fixed;
  height: $header-size;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: $background-header;
  color: $foreground-header;
  @include tile-shadow;

  //background: linear-gradient(-90deg, $background-header 0%, rgba(182,4,79,1) 100%); // spotlight ion copservation
  background: linear-gradient(
    90deg,
    rgba(150, 23, 52, 1) 0%,
    rgba(182, 4, 79, 1) 100%
  ); // bpb <-> correctiv
  background: linear-gradient(
    -90deg,
    #3e7a5e 0%,
    #223f62 100%
  ); // bpb <-> correctiv
  //background: rgb(150,23,52); Bordoux in besser
  //background: linear-gradient(90deg, #0c412b 33%, #245440 100%); //Bottlegreen pool tisch
  //background: linear-gradient(90deg,  #ff3468  33%,  #e52e5d 100%); // In your face
  color: white;
  //background-color:#212c5a; // N64

  //background: rgb(164,23,38);
  //background: linear-gradient(90deg, rgba(164,23,38,1) 40%, rgba(129,0,32,1) 100%); // agreeable red
  //background: linear-gradient(90deg, #344861 33%, #485a70 100%); //Airforce + Highlight
  //background: linear-gradient(90deg, #ff3468 0%, #344861 100%); // spotlight ion copservation
  //background: rgb(4,3,18);// Sunshine on the side
  //background: linear-gradient(90deg, rgba(4,3,18,1) 0%, rgba(51,23,40,1) 32%, rgba(104,7,11,1) 79%, rgba(116,54,37,1) 94%);

  &.Hidden {
    position: unset;
    visibility: hidden;
  }

  .Logo {
    margin-top: $distance-small;
    margin-left: $distance-big;
    margin-right: $distance-big;
    margin-bottom: $distance-small;
    height: 50px;
  }

  .Banner {
    margin-left: $distance-big;
    cursor: pointer;
  }

  a.Banner__Link {
    display: inline-block;
    height: $header-size;
  }

  img {
    margin-top: 10px;
    height: 60px;
    margin-right: 24px;
  }

  .main-menu--desktop ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    // // flex-direction: column;
    // @include responsive(large) {
    //   flex-direction: row;
    // }

    li {
      display: flex;
      flex-wrap: nowrap;
      flex: 1;

      a {
        display: block;
        color: inherit;
        text-decoration: none;
        font-size: $font-size-big;
        white-space: nowrap;
        padding: $distance-big 24px;
      }

      &:hover {
        background-color: #7e8b9a;
        color: black;
      }

      // for tabbing through; affects mutually parent nav li through child ahref
      & > a:focus {
        background-color: #7e8b9a;
        color: black;
      }

      // when a user mousedowns li
      & > a:active {
        background-color: lighten(#7e8b9a, 15%);
      }
    }
  }
}

.menu-wrapper {
  margin-right: 45px;
  @include responsive(small) {
    margin-right: 20px;
  }
}
// default menu; is rendered inside component only if screensize is < 768px
.main-menu {
  background: #fff;
  position: absolute;
  top: $header-size;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.1s ease-in;
  visibility: hidden;
  box-shadow: 0 24px 18px rgba(0, 0, 0, 0.4);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li:not(:last-child) {
    border-bottom: 1px solid #ddd;
    &:hover {
      background-color: #ddd;
    }
  }

  li a {
    text-decoration: none;
    color: #333;
    padding: 1.2rem 2.4rem;
    display: block;
  }

  ul.social-media {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.8rem 2.4rem;

    li {
      border: 0 solid transparent;

      &:hover {
        background-color: transparent;
      }
    }

    a {
      border-radius: 4px !important;
      margin: 0.4rem 1.2rem 0.4rem 0;
      padding: 0.1rem 0.4rem;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      color: #fff;

      &.link--twitter {
        background: linear-gradient(45deg, lighten(#1da1f2, 25%), #1da1f2);
      }
      &.link--facebook {
        background-color: #4267b2;
      }
      &.link--instagram {
        background: linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
        );
      }

      svg {
        // filter: invert(1); // used for displaying the icon in white
        fill: #fff;
      }

      &:hover {
        box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.3);
      }
    }
  }

  // purely visuall menu open indicating arrow
  .arrow-up {
    position: absolute;
    right: 57px; // 45 margin right of button + 13 half width  of button
    top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }
}

// the button elem which opens the menu
.menu-trigger {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  border: 2px solid rgba(#00c697, 0.5);
  cursor: pointer;
  padding: 4px 6px;
  vertical-align: middle;

  &:hover {
    border-color: #00c697;
  }
  &:active,
  &:focus {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }

  .menu-icon {
    vertical-align: middle;
    font-size: 1.4rem;
    margin: 0 0.2rem;
  }
}
