@import "vars";

.ArticleButton {
  text-decoration: none;
  color: #444;
  font-size: 13px;
  white-space: nowrap;
  position: relative;
  top: -1px;
  margin: #{$distance-tiny};

  svg {
    margin-right: 5px;
    opacity: 0.9;
  }

  &:hover {
    color: #000;
    text-decoration: underline;
  }

  img,
  span {
    display: inline-block;
  }
}

.TagButton {
  display: inline-block;
  position: relative;
  margin: #{$distance-tiny};
  //padding:$distance-tiny;
  //background-color: $background-category-color;
  color: #444;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    //background-color: $background-category-highlight-color;
    color: #000;
  }

  svg {
    margin-right: 5px;
  }
}

.TagButton {
  &:hover .Popup,
  &:focus .Popup {
    opacity: 1;

    .Popup__Content {
      display: block;
    }
  }
  &:hover .Popup {
    transition-delay: 0.5s;

    .Popup__Content {
      display: block;
    }
  }
}

.Button {
  background-color: #ccc;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: #999999;
  }

  // used e.g. "Alle Filter zurücksetzen"
  &.Button--Fake {
    background-color: transparent;
    color: #8d8d8d;
    text-decoration: underline;

    svg {
      margin-right: 4px;
    }

    &:hover {
      color: #999999;
    }

    &.right {
      text-align: right;
    }
  }
}
