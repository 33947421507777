@import "vars";
@import "fonts";
@import "base";
@import "incident_text";
@import "buttons";
@import "structure";
@import "header";
@import "footer";
@import "leaflet";
@import "popup";
@import "report";
@import "noscript";

// aka "teaser"

div.TitleBar {
  background-size: 100%;
  background-position: bottom;
}

.TitleBar {
  width: 100%;

  @include responsive(large) {
    width: 100%;
    max-width: 1200px;
  }

  display: flex;
  //background-color: $background-alt-color;
  background-color: #0000 !important;
  color: $foreground-alt-color;
  margin-bottom: $distance-big;
  text-align: center;
  min-height: min(calc(100vw * 0.2 * 0.55), 200px);
  max-width: 1200px;
  position: relative;
  object-fit: cover;

  &::after {
    content: "";
    background-image: url("../images/title_overview.png");
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-size: calc(100% * 1.8);

    @include responsive(large) {
      background-size: 1800px;
    }
  }

  .TextBox {
    text-align: left;
    margin: 0;
    line-height: 1.4;
    font-size: 22px;
    font-weight: 250;
    padding: 30px;
    padding-right: 50px;
    color: #111;
    text-shadow: 0 0 2px rgba(246, 246, 246, 0.1);
    /*
        background: radial-gradient(
                        circle at 100%,
                        rgba(246,246,246,.2) 10%,
                        rgba(246,246,246,.8) 50%,
                        rgba(246,246,246, 1) 75%
                    );
                    */
    @include responsive(medium) {
      font-size: 14px;
      padding: 15px;
    }
    @include responsive(small) {
      font-size: 10px;
      padding: 15px;
    }

    strong {
      font-weight: 350;
    }

    p {
      display: inline;
      color: black;
      background-color: #fffd;
      line-height: 1.45;
    }
  }
}

// the boxes all around, used in articles, filter sidebar, main
.tile {
  background-color: $tile-background-color;
  @include tile-shadow;
  width: 100%;
}

/*
 * Incident Overview
*/

.Visualization {
  margin-bottom: $distance-big;
  padding: 16px;

  .Title {
    display: flex;
    font-size: $font-size-medium;
    background-color: $background-title-color;
    color: $foreground-title-color;
    margin-bottom: $distance-small;
  }

  h2 {
    text-align: left;
    margin: 0;
    margin-bottom: 4px;
    font-size: 20px;
    flex: 1;
    font-weight: 400;
    color: #393939;
  }

  .Visualizer {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    margin: 0;

    li {
      background-color: $background-category-color;
      color: $foreground-category-color;
      cursor: pointer;
      flex: 1;
      text-align: center;

      &.active {
        background-color: $background-category-highlight-color;
        color: $foreground-category-highlight-color;
      }

      a {
        display: block;
        color: inherit;
        height: 100%;
        width: 100%;
        text-decoration: inherit;
        padding: $distance-small;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
}

// Below teaser, full width, includes map/list + rightbar
.Content {
  box-sizing: border-box;
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include responsive(medium) {
    margin: 24px;
  }

  @include responsive(large) {
    max-width: 1200px;
    margin: 0;
    flex-direction: row;
  }

  .MapContainer,
  .Graph,
  .Timeline,
  .TextContainer {
    margin-right: 30px;
    flex-grow: 1;
    width: 100%;

    @include responsive(medium) {
      margin-bottom: 30px;
    }
  }
}

.RightBar {
  height: auto;
  min-width: 300px;
  max-width: 300px;

  @include responsive(small) {
    margin-top: 50px;
    max-width: 100%;
  }
  @include responsive(medium) {
    max-width: 100%;
  }
}

.Filters {
  background-color: $background-alt-color;
  padding: 16px;
  // overflow-y: auto; @TODO once used to trigger resizing event for map rendering, still buggy though
  margin-bottom: 50px;

  h2 {
    margin: 0;
    margin-bottom: 14px;
    font-weight: 400;
    font-size: 20px;
    color: #393939;
  }

  .Expand {
    font-size: $font-size-small;
    margin-right: $distance-medium;
    cursor: pointer;
  }

  .Delete {
    cursor: pointer;
  }

  .Filter {
    margin-bottom: 32px;

    // .Filter__Title
    &__Title {
      display: flex;
      font-size: $font-size-medium;
      background-color: $background-title-color;
      color: $foreground-title-color;
      text-align: left;
      margin-bottom: 6px;
      font-size: $font-size-medium;
      font-weight: 250;
      flex: 1;

      &--small {
        text-align: left;
        padding: $distance-small;
        margin: 0;
      }
    }

    // .Filter__Content
    &__Content {
      max-width: 100%;
      background-color: #fff;

      .optionListContainer {
        .optionContainer {
          li.highlight,
          li.option:hover {
            color: #ddd;
            background-color: #223f62;
          }
        }
      }

      .search-wrapper {
        padding: 0;
        border: 0;
        background-color: #e9e9e9;
        padding: 5px;

        .chip {
          background-color: #223f62;
          color: #ddd;
        }
      }

      input[type="text"] {
        width: 100%;
        padding: 8px;
        background-color: #e9e9e9;
        font-size: 14px;
        font-weight: 400;
      }
    }

    svg {
      margin-left: auto;
      margin-right: 8px;
    }
  }

  .filter__date-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
  }

  input[type="date"] {
    background-color: #fff;
    font-family: $font-stack;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    min-height: 22px;
    position: relative;
    width: 50%;
  }

  .filter__small-description {
    color: lighten($text-color, 50%);
    font-size: 11px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1px 2px;
  }
}

.Teaser {
  background-color: $background-alt-color;
  padding: $distance-big;
  margin-bottom: $distance-big;
  font-size: $font-size-big;
}

.Page {
  display: flex;
  flex-direction: row;
  flex: 1;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }

  &--topMargin {
    margin-top: 15px;
  }

  .SideBar {
    flex: 1;
    margin-left: $distance-big;
    @media screen and (max-width: 768px) {
      margin: 0;
      margin-top: 3em;
    }

    .TextBlock {
      flex: 1;
      background-color: $background-alt-color;
      padding: $distance-small;
      margin-right: 0;

      &:first-of-type {
        margin-top: 0;
      }

      &__Title {
        margin: 0;
        padding: $distance-small;
        background-color: $background-title-color;
        font-weight: 400;
        font-size: 20px;
        color: #393939;
      }

      &__Content {
        padding: $distance-small;
      }

      &:not(:first-of-type) {
        margin-top: $distance-medium;
      }
    }
  }
}

.Pagination {
  display: flex;
  margin-top: $distance-medium;
  grid-column: span 1;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;

  &__Item {
    margin: #{$distance-small * 0.5};

    a {
      @include responsive(small) {
        padding: 4px 10px;
      }
    }

    background-color: $background-alt-color;
    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
    }

    &--active {
      background-color: rgb(170, 170, 170);
    }

    & > * {
      text-decoration: inherit;
      color: inherit;
      padding: ($distance-medium - 5px) $distance-medium;
      display: block;
    }
  }
}

.Loading {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000000;
  display: flex;
  align-items: center;

  .Container {
    background-color: white;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;

    svg {
      display: inline-block;
      margin-right: 15px;
    }

    @include responsive(small) {
      width: 70%;
    }
    @include responsive(medium) {
      width: 50%;
    }
  }

  &--active {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
  }

  &--hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }

  @include responsive(small) {
    font-size: $font-size-medium;
  }
  @include responsive(medium) {
    font-size: $font-size-big;
  }
}

.Location,
.Date {
  svg {
    margin-right: 5px;
  }
}

.TagButton.Location {
  white-space: normal;
}

.PlaceSearchBox {
  position: relative;
  text-align: left;
  width: 100%;
  padding: 5px;
  min-height: 22px;
  position: relative;
  background-color: #e9e9e9;

  .PlaceSearchBox__Selections {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      padding: 4px 10px;
      background: #223f62;
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      font-size: 13px;
      color: #ddd;
      white-space: nowrap;
      white-space: break-spaces;

      svg {
        font-size: 12px;
        float: right;
        margin-left: 4px;
        cursor: pointer;
      }
    }
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .PlaceSearchBox__Popup {
    position: absolute;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    z-index: 2;
    margin-left: -3px;
    margin-top: 1px;

    ul {
      display: block;
      padding: 0;
      margin: 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 250px;
      overflow-y: auto;

      li {
        padding: 10px 10px;
        white-space: break-spaces;
        cursor: pointer;

        span {
          margin-right: 5px;
        }

        &:hover {
          background: #223f62;
          color: #ffffff;
          cursor: pointer;
        }

        &.active {
          background: #223f62;
          color: #ffffff;
        }
      }
    }
  }
}

.HelpSection {
  margin-bottom: 5px;

  &__Title {
    background-color: lightgray;
    display: flex;
    align-items: baseline;
    cursor: pointer;
    flex-wrap: nowrap;

    h3 {
      margin: 15px;
      padding: 0;
      font-weight: 300;
      font-size: 20px;
    }

    svg {
      align-self: center;
      font-size: 30px;
      margin: 15px;
      margin-left: auto;
    }
  }

  &__SubSection {
    padding: 16px;
    border: 1px solid lightgray;
    border-top: 0;
  }
}

.HelpGroup {
  margin-bottom: 10px;

  &__Title {
    display: block;
    padding: 10px 26px;
    margin-left: -16px;
    margin-right: -16px;
    font-weight: 400;
    background: linear-gradient(60deg, #529e95, 40%, #8bb3a0 100%, transparent);

    &.polizeilicheBeschwerdestelle {
      background: linear-gradient(
            60deg,
            #529e95 20%,
            rgba(#8bb3a0, 0.85) 100%,
            transparent
          )
          no-repeat right center,
        url("../images/help-polizeiliche-beschwerdestelle-caution.png")
          no-repeat 100px 70%;
      background-size: cover;
    }

    &__SubTitle {
      display: block;
      font-size: 13px;
      font-weight: 375;
    }
  }

  &__Content {
    padding: 10px;

    p {
      margin-bottom: 16px;
    }
    small {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  address {
    font-size: 16px;
    font-style: normal;
  }
}

.adjusted-typographic {
  // tnum - fixed-width numbers are useful for tabular data
  // case - switches out some glyphs to work better with capital letters and numbers
  // ss01 - an alternate style of digits
  // ss02 - alternate glyph set that increases visual difference between similar-looking characters
  font-feature-settings: "tnum" on, "case" on, "ss01" on, "ss02" on;
}

sup {
  font-feature-settings: "sups" on;
  white-space: nowrap;

  a {
    padding: 3px;
    text-decoration: none;
    letter-spacing: 1px;
  }
}

.SocialMediaIcon {
  font-size: 50px;
}

.footnotes {
  margin-top: 3.6rem;
  font-feature-settings: "case" on;

  h3 {
    margin: 0;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 350;
  }

  p {
    font-size: 13px;
  }
}

.AppError {
  padding: 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  &__Header {
    width: 100%;
    display: flex;
    align-items: center;

    svg {
      font-size: 52px;
      margin: 20px;
    }
  }

  &__Content {
    margin: 20px;
    display: flex;

    p,
    pre {
      margin: 5px;
    }

    pre {
      width: 100%;
      padding: 20px;
      border: 2px solid gray;
    }
  }
}

// @TODO bvreakpoints
@media screen and (min-width: 768px) {
  .sticky {
    position: sticky;
    top: calc(84px + 30px);
    z-index: 500;
  }
}

.DatePicker {
  display: flex;
  align-items: center;
  background-color: #e9e9e9;

  input {
    border: 0;
    background-color: inherit;
    padding: 5px;
  }
}

.Warning {
  display: flex;
  background-color: #b3106744;
  color: #0009;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  span {
    padding-left: 10px;
    padding-right: 10px;
  }

  span.Help__Icon {
    svg {
      color: #0009;

      &:hover {
        color: #000c;
      }
    }
  }
}

.mini-scrollbar::-webkit-scrollbar {
  width: 18px;
}
.mini-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.mini-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.mini-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

// overwrite of 3rd-party-content inside filter multiselection dropdown
#filter-hashtags_input {
  margin-top: 0;
}
// overwrite of 3rd-party-content inside filter date picker
.react-datepicker__input-container {
  padding: 5px;
}

// Change flex order if list view
@media screen and (min-width: 1200px) {
  .Content--text {
    flex-direction: row-reverse;
  }
}

// Different headers
h3 {
  margin-bottom: 0.3em;
}

h4 {
  margin-bottom: 0.2em;
}
