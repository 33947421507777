// header & content
@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

@font-face {
  font-family: "RutanMedium";
  src: local("RutanMedium"), url(./rutan-medium.woff) format("woff");
}
