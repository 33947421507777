@import "vars";

* {
  border-radius: 0 !important;
  box-sizing: border-box;
}

p {
  margin-top: 0;
  font-weight: 200;
}

body {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: $font-stack;
  color: $text-color;
  background-color: $background-color;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

main {
  margin: $distance-big;
  margin-top: 24px;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

// TODO interfers with popup incident
th {
  font-weight: 500;
}

.Span {
  flex: 1;
}

strong {
  font-weight: 450;
}

.Mono {
  font-family: "mono";
}

.Keyblock {
  display: flex;
  pre {
    flex: 0;
    margin-left: auto;
    margin-right: auto;
    font-family: "mono";
    font-size: 10px;
    padding: 15px;
    background-color: lightgray;
    border: 1px solid black;
    text-align: left;
    margin-left: auto;
    margin-right: auto;

    @include responsive(small) {
      font-size: 4px;
    }
  }
}

.SpecialToggle {
  font-size: 12px;
  margin-left: 10px;
  align-self: center;
  cursor: pointer;

  svg {
    font-size: 16px;
    color: #223f62;
  }

  &--on svg {
    transform: rotate(180deg);
  }
}

input[type] {
  font-family: $font-stack;
}
