@import "vars";

.Incidents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  grid-gap: $distance-big;
  width: 100%;
  @include responsive(small) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.Incident {
  &__Title {
    display: flex;
    background-color: $background-title-color;
    align-items: baseline;
    font-size: $font-size-big;
    justify-content: space-between;
    margin: $distance-big;
    color: #393939;

    &:hover {
      color: #000;
    }

    h4 {
      margin: 0px;
      margin-right: $distance-small;
      padding: 0px;
      text-align: left;
      font-weight: inherit;
      font-size: inherit;
    }

    a {
      text-decoration: inherit;
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }

  &__SocialMedia {
    a {
      color: #888;
    }
  }

  &__Content {
    margin: $distance-big;
    text-align: left;

    .Incident__Extras {
      margin-top: $distance-big;

      table {
        th {
          vertical-align: top;
          padding-right: 30px;
          font-size: 13px;
          color: #888;
          font-weight: 350;
          padding: #{$distance-tiny};
        }

        tr:hover th {
          color: #333;
        }

        .TagButton {
          color: #888;

          &:hover {
            color: #333;
          }
        }
      }
    }
  }

  &__SocialMedia {
    margin: $distance-medium;
    display: flex;
    color: #888;
    align-items: center;
    font-size: $font-size-small;
    justify-content: flex-end;
    font-size: 16px;

    div,
    span,
    svg {
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;

      &:hover {
        color: $foreground-title-color;
      }
    }
  }
}

.Incident--popup {
  display: flex;
  flex-direction: column;
  .Incident__Title {
    font-size: 18px;
    margin: $distance-medium;
  }

  .Incident__Content {
    overflow-y: auto;
    overflow-x: auto;
  }

  .Incident__Teaser {
    font-size: 14px;
  }

  .Incident__Content {
    margin: $distance-medium;

    .Incident__Extras {
      margin-top: $distance-medium;

      table th {
        padding-right: 5px;
        font-size: 13px;
        color: #888;
        font-weight: 350;
      }
    }
  }

  .Popup {
    display: none !important;
  }
}
