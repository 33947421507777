@import "vars";

// basic link styling
.TextArticle a,
article a {
  color: #223f62;
  transition: all 0.1s cubic-bezier(0.33, 0.66, 0.66, 1);
  padding-left: 1px;
  padding-right: 1px;
  overflow-wrap: break-word;

  &:hover {
    color: #3e7a5e;
  }

  // icon indicator appended for mostly external links
  &[target="_blank"]:not(.NoArrow):after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
  }
}

.TextArticle {
  flex: 1 1 66%;
  margin: 0;
  background-color: $background-alt-color;
  color: $foreground-alt-color;
  padding: 24px;

  @include responsive("small") {
    padding-left: 10px;
    padding-right: 10px;
  }

  // <h2>
  &__Title {
    display: flex;
    padding: $distance-small;
    font-size: $font-size-medium;
    background-color: $background-title-color;
    color: $foreground-title-color;
    font-size: 30px;
    font-weight: 400;
    margin: 0;
  }

  h3 {
    font-weight: 400;

    strong {
      font-weight: 450;
    }
  }

  &__Content {
    padding: $distance-small;
    flex: 1;
    max-width: 75ch;
    line-height: 1.5;
    font-size: $font-size-big;

    .Quote {
      display: flex;
      justify-content: center;
      margin-top: $distance-big;
      margin-bottom: $distance-big;

      &__Content {
        min-width: 50%;
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        padding-top: $distance-medium;
        padding-bottom: $distance-medium;
        display: inline-block;
        font-size: $font-size-bigger;
        text-align: center;
      }
    }
  }
}
