@import "vars";

.FooterWrapper {
  margin-top: auto;
}

.Footer {
  color: #ddd;
  background-color: #111;
  min-height: 200px;
  padding: 48px;
  margin-top: #{$distance-big * 3};
  font-size: 12px;

  @include responsive() {
    font-size: 14px;
    padding: 24px;
  }

  .footer-content-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: top;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @include responsive() {
      flex-direction: column;
    }
  }

  &__Block {
    display: flex;
    flex-direction: column;

    &--horizontal {
      flex-direction: row;
      align-self: flex-start;
    }

    @include responsive() {
      margin-top: 30px;
    }
  }

  span,
  strong {
    margin-top: $distance-tiny;
    margin-left: 24px;
    margin-bottom: $distance-tiny;
    margin-right: $distance-big;

    a {
      padding: 4px;
      margin-left: -4px;
      color: rgb(141, 141, 141);
      text-decoration: none;
      transition: all 0.1s ease-in;
      outline: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
  strong {
    color: #bbb;
  }

  svg {
    margin-left: $distance-medium;
    margin-right: $distance-medium;
    margin-bottom: $distance-big;
    padding: 6px;
    border-radius: 4px !important;
    fill: rgba(255, 255, 255, 0.4);
    transition: background-color 0.1s ease-in;

    path,
    circle {
      fill: rgba(255, 255, 255, 0.4);
    }

    @include responsive() {
      margin-left: 20px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .Footer__Block--horizontal {
    padding-right: 3px;

    a {
      outline: none;
    }
    a:focus svg {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}
