$color-white: #f7f7f7;
$background-alt-color: #eeeeee;
$foreground-alt-color: #111;
// Real background
$background-color: #fff; // <<<<< HEADER BACKGRIOUND COLIOR
$foreground-color: #111;
$background-alt-color: $background-color;
$text-color: #222;
$font-stack: "Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$tile-background-color: #fff;
// Header Settings TODO
//$background-header: #00141e; // <<<<< HEADER
// Ini Türkis $background-header: #167080; // <<<<< HEADER
/*hell:
bg: #0052CC
text: #fefefe;

dunkel:
bg: #172B4D
text: #fff
*/
//$background-header: #00c4d4;
//$background-header: #56020a;
//$background-header: #bb1a39;
$background-header: #660066;

$foreground-header: white; // <<<<< HEADER
$background-header-highlight-color: #192b34;
$foreground-header-highlight-color: white;

$background-highlight-color: #91d4c2;
$foreground-highlight-color: #111;
$background-nonhighlight-color: $background-header;
$foreground-nonhighlight-color: #111;

// TITLE TODO
$background-title-color: inherit;
$foreground-title-color: black;

$background-category-color: #ccc;
$foreground-category-color: $foreground-nonhighlight-color;
$background-hashtag-color: $background-nonhighlight-color;
$foreground-hashtag-color: $foreground-nonhighlight-color;
$background-category-highlight-color: #999;
$foreground-category-highlight-color: $foreground-highlight-color;
$background-hashtag-highlight-color: $background-highlight-color;
$foreground-hashtag-highlight-color: $foreground-highlight-color;

$distance-tiny: 5px;
$distance-small: 10px;
$distance-medium: 15px;
$distance-big: 30px;

$font-size-tiny: 10px;
$font-size-small: 12px;
$font-size-medium: 15px;
$font-size-big: 20px;
$font-size-bigger: 25px;

$header-size: 84px;

// used in all content containing tiles
$tile-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.14),
  0 4px 3px -2px rgba(0, 0, 0, 0.12);

/**
@name: tile-shadow
@description: shadow level applied to tiles
@usage:
  elem {
    @include tile-shadow;
  }
*/
@mixin tile-shadow() {
  $tile-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.14),
    0 4px 3px -2px rgba(0, 0, 0, 0.12);
  $tile-shadow-hover: 0 4px 12px 0 rgba(0, 0, 0, 0.18),
    0 4px 3px -2px rgba(0, 0, 0, 0.16);

  box-shadow: $tile-shadow;
  transition: all ease-in-out 0.1s;

  &:hover {
    box-shadow: $tile-shadow-hover;
  }
}

// BREAKPOINTS
// small 0-767, medium 768-1199, large 1200 and above
@mixin responsive($breakpoint: "small") {
  // MEDIUM
  @if $breakpoint == "medium" {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  }

  // LARGE
  @else if $breakpoint == "large" {
    @media screen and (min-width: 1200px) {
      @content;
    }
  }

  // FALLBACK TO SMALL
  @else {
    @media screen and (max-width: 767px) {
      @content;

      header nav .main-links li a {
        font-size: 10px;
      }
    }
  }
}
